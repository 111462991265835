import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/button/button.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/checkbox/checkbox.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/close-button/close-button.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/combobox/combobox.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/data-interactive/data-interactive.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/description/description.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/dialog/dialog.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/disclosure/disclosure.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/field/field.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/fieldset/fieldset.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/focus-trap/focus-trap.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/input/input.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/label/label.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/legend/legend.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/listbox/listbox.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/menu/menu.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/popover/popover.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/portal/portal.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/radio-group/radio-group.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/select/select.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/switch/switch.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/tabs/tabs.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/textarea/textarea.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/@headlessui+react@2.1.1_react-dom@18.2.0_react@18.2.0/node_modules/@headlessui/react/dist/components/transition/transition.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/next@14.0.1_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/node_modules/.pnpm/next@14.0.1_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/public/assets/images/logos/be-logo.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/assistant/self-guided/SelfGuidedAssistant.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/button/Button.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/catalyst/dropdown.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/catalyst/navbar.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/catalyst/sidebar-layout.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/catalyst/sidebar.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/catalyst/stacked-layout.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/catalyst/table.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/combobox/Combobox.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/form/ZodForm.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/nav/BusinessAdvisorNav.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/nav/CompanyNav.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/provider/AuthProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/provider/NotificationsProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/provider/PersonnelProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/provider/UnsavedChangesProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/provider/UserContextProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/resource-plan/self/CreateSelfGuidedResourcePlanCard.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/finx-app/finx-app/packages/frontend/src/components/salary-calculator/SalaryCalculator.tsx")